import React, { Component } from 'react';
import { Row, Col, Divider, Upload, Modal, Icon, List, message, Button, Badge } from 'antd';
import Parse from 'parse';
import { find, filter } from 'lodash';
import moment from 'moment';
import { CleanLayout } from '../../layout/CleanLayout';
import GlobalForm from '../../components/GlobalForm';
import { TasksInfoFields, Status_List } from '../../utils/utils';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


// De forma exclusiva, el Service Order Type CG EXT debe permitir subir los siguientes campos de ficheros
const filesForCGEXT = [
  {
    name: 'Certificado almacenamiento',
    filename: 'certificado-almacenamiento'
  },
  {
    name: 'Certificado Instalación Envases',
    filename: 'certificado-instalacion-envases'
  },
  {
    name: 'Certificado fabricación depósito',
    filename: 'certificado-fabricacion-deposito'
  },
  {
    name: 'IRG3 o IRG4',
    filename: 'IRG3-O-IRG4'
  },
  {
    name: 'IRG1 o IRG2',
    filename: 'IRG1-O-IRG2'
  },
  {
    name: 'Certificado térmicas',
    filename: 'certificado-termicas'
  },
  {
    name: 'Contrato mantenimiento',
    filename: 'contrato-mantenimiento'
  },
  {
    name: 'Memoria',
    filename: 'memoria'
  },
  {
    name: 'Proyecto instalación exterior',
    filename: 'proyecto-instalacion-exterior'
  },
  {
    name: 'Proyecto instalación receptora',
    filename: 'proyecto-instalacion-receptora'
  },
  {
    name: 'Dirección obra instalación exterior',
    filename: 'direccion-obra-instalacion-exterior'
  },
  {
    name: 'Dirección obra instalación receptora',
    filename: 'direccion-obra-instalacion-receptora'
  },
  {
    name: 'Certificado inertizado',
    filename: 'certificado-inertizado'
  },
  {
    name: 'Registro Industria',
    filename: 'registro-industria'
  },
  {
    name: 'Check-lists inspección OCA (exterior y receptora)',
    filename: 'check-lists-inspección-OCA'
  },
  {
    name: 'Certificado pruebas previas receptora',
    filename: 'certificado-pruebas-previas-receptora'
  },
  {
    name: 'Acta Inspección Inicial OCA',
    filename: 'acta-inspeccion-inicial-OCA'
  },
  {
    name: 'Acta primer llenado',
    filename: 'acta-primer-llenado'
  },
];

// const testForm = [
//   {
//     id: 'test-1',
//     type: 'text',
//     label: 'Test 1',
//     placeholder: 'introduce...'
//   },
//   {
//     id: 'test-2',
//     type: 'select',
//     label: 'Test 2',
//     placeholder: 'introduce...',
//     options: [
//       { text: 'one', value: '1'},
//       { text: 'two', value: '2'}
//     ]
//   }
// ];

export class TaskPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: null,
      taskData: null,
      note: null,
      media: [],
      alerts: [],
      form: null,
      previewVisible: false,
      previewFile: null,
    }
    this.fetch()
  }

  fetch = async (params = {}) => {
    //console.log('params:', params);
    let taskId = this.props.location.pathname.replace('/task/', '');
    this.setState({ loading: true });
    const Task = Parse.Object.extend('Task');
    const query = new Parse.Query(Task);
    query.equalTo('objectId', taskId);
    let task = await query.first()
    .then(result => {
      return {
        ParseObject: result,
        ...result.toJSON()
      }
    })
    .catch(err => null);
    console.log(task)

    const TaskData = Parse.Object.extend('TaskData');
    const queryData = new Parse.Query(TaskData);
    queryData.equalTo('task', { __type: 'Pointer', className: 'Task', objectId: taskId });
    let taskData = await queryData.first()
    .then(result => result.toJSON())
    .catch(err => null);
    console.log(taskData)

    const Note = Parse.Object.extend("Note");
    const queryNote = new Parse.Query(Note);
    queryNote.equalTo("task", { __type: "Pointer", className: "Task", objectId: taskId });
    let note = await queryNote.first()
    .then((data) => data?.attributes?.text || null)
    .catch((err) => null)

    const Media = Parse.Object.extend('Media');
    const queryMedia = new Parse.Query(Media);
    queryMedia.equalTo('task', { __type: 'Pointer', className: 'Task', objectId: taskId });
    let media = await queryMedia.find()
    .then(results => results.map(r => r.toJSON()))
    .catch(err => []);
    console.log(media)

    const Alert = Parse.Object.extend('Alert');
    const queryAlert = new Parse.Query(Alert);
    queryAlert.equalTo('task', { __type: 'Pointer', className: 'Task', objectId: taskId });
    let alerts = await queryAlert.find()
    .then(results => results.map(r => r.toJSON()))
    .catch(err => []);
    console.log(media)

    this.setState({
      task,
      taskData,
      note,
      media,
      alerts
    }, () => {
      this.getForm()
    });
  }

  getForm = async () => {
    const { task } = this.state;
    const forms = await import('../../data/forms.json');
    console.log('getForms', forms);
    let serviceId = task.Service_Order_Type;
    if(serviceId === 'CG EXT' && task.Status === 'On_Hold') {
      serviceId = 'CG EXT PDF';
    }
    const form = find(forms, { service: serviceId });
    console.log('form', form);
    this.setState({
      form: form
    })
  };

  renderDataField(field) {
    //console.log(field)
    if(field) {
      if(field.__type === 'Date') {
        return moment(field.iso).format('DD/MM/YYYY');
      }
      return field;
    } else {
      return '...';
    }
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewFile: {
        ...file,
        base64: file.url || file.preview
      },
      previewVisible: true,
    });
  };

  onChange = (info) => {
    console.log('info', info)
  };

  beforeUploadImages = (file) => {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    const validFiles = [
      'image/jpeg',
      'image/png',
      'image/tiff'
    ];
    const isValidFile = validFiles.includes(file.type);
    if (!isValidFile) {
      message.error('Error. Solo puedes subir archivos de tipo imagen (jpeg, png, tiff).');
    }
    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      message.error('Error. El archivo debe ocupar menos de 25MB.');
    }
    return isValidFile && isLt2M;
  }

  beforeUpload = (file) => {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    const validFiles = [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'image/tiff',
      'video/mp4',
      'video/x-msvideo', // .avi
      'video/quicktime', // .mov
      'video/x-m4v' // .m4v
    ];
    const isValidFile = validFiles.includes(file.type);
    if (!isValidFile) {
      message.error('Error. Solo puedes subir archivos de tipo documento (pdf), imagen (jpeg, png, tiff), y vídeo (mp4, avi, mov, m4v).');
    }
    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      message.error('Error. El archivo debe ocupar menos de 25MB.');
    }
    return isValidFile && isLt2M;
  }

  handleImageUpload = ({file, onSuccess}) => {
    console.log('file', file)
    const reader = new FileReader();
    reader.onloadend = async (obj) => {
      this.imageDataAsURL = obj.srcElement.result;
      let uploaded = await this.uploadMedia({
        info: file,
        base64: obj.srcElement.result,
        type: 'image'
      });
      if(uploaded) {
        this.setState({
          media: this.state.media.concat(uploaded)
        })
      }
      console.log('file', this.imageDataAsURL)
      onSuccess('ok')
    };
    reader.readAsDataURL(file);
  };

  handleFileUpload = ({file, fileName = null, onSuccess}) => {
    console.log('file', file)
    const { task, media } = this.state;
    const reader = new FileReader();
    reader.onloadend = async (obj) => {
      this.imageDataAsURL = obj.srcElement.result;
      let newFileName = `${task.No}_${task.Customer_No}_${moment().format('YYYYMMDDHHmmss')}.${file.type.split('/')[1]}`;
      if(fileName) {
        let files = filter(media.map(item => {
          const urlCheck = item.url.slice(0, 5);
          if(urlCheck != "data:"){
            message.error('Error. Por seguridad hemos bloqueado la subida del fichero.');
            return false;
          }
          console.log("media map item ", item);
          return {
            uid: item.objectId,
            name: item.fileName,
            status: 'done',
            url: item.url,
          }
        }), (item) => {
          if(item.name.indexOf(fileName) !== -1) {
            return item;
          }
        });
        let filesCount = files.length + 1;
        newFileName = `${fileName}_${filesCount}.${file.type.split('/')[1]}`;
      }
      let uploaded = await this.uploadMedia({
        info: {
          ...file,
          name: newFileName
        },
        base64: obj.srcElement.result,
        type: 'document'
      });
      if(uploaded) {
        this.setState({
          media: this.state.media.concat(uploaded)
        })
      }
      console.log('file', this.imageDataAsURL)
      onSuccess('ok')
    };
    reader.readAsDataURL(file);
  };

  uploadMedia = ({info, base64, type}) => {
    console.log("base64", base64);
    const urlCheck = base64.slice(0, 5);
    if(urlCheck != "data:"){
      message.error('Error. Por seguridad hemos bloqueado la subida del fichero.');
      return false;
    }
    const { task } = this.state;
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      const Media = Parse.Object.extend('Media');
      const media = new Media();
      return await media.save({
         task: { __type: 'Pointer', className: 'Task', objectId: task.objectId },
         owner: { __type: 'Pointer', className: '_User', objectId: user.id },
         url: base64,
         fileName: `${task.Customer_No}_${task.No}_${task.Installation_No}_${info.name}`,
         type: type
      })
      .then(result => {
        resolve(result.toJSON())
      })
      .catch(err => {
        reject(null)
      });
    });
  };

  deleteFile = async (e) => {
    console.log('deleteFile', e)
    Modal.confirm({
    title: 'Confirmar',
    content: '¿Está seguro de que desea eliminar el elemento?',
    okText: 'Sí',
    cancelText: 'No',
    onOk: async (ev) =>  {
      const Media = Parse.Object.extend('Media');
      const query = new Parse.Query(Media);
      let obj = await query.get(e.uid);
      let deleted = obj.destroy()
      .then(result => result)
      .catch(err => null);
      if(deleted) {
        let files = [];
        this.state.media.forEach((item, i) => {
          if(e.uid !== item.objectId) {
            files.push(item);
          }
        });
        this.setState({
          media: files
        });
      }
    }
  });

  };

  deleteTask = (e) => {
    e.preventDefault()
    const { isAdmin } = this.props;
    const { task } = this.state;
    if(!isAdmin) return;
    Modal.confirm({
      title: `¿Eliminar la tarea ${task.No}?`,
      content: 'La tarea no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        task.ParseObject.destroy()
        .then(destroyed => {
          message.success(`Tarea ${task.No} eliminada.`)
          window.location.href=window.location.origin;
        })
        .catch(err => {
          message.error(`Error eliminando la tarea ${task.No}.`)
        });
      },
    });

  };

  render() {
    const { isAdmin } = this.props;
    const { form, task, taskData, note, media, alerts, previewVisible, previewFile } = this.state;
    // console.log('Task render', this);

    let images = filter(media, { type: 'image' });
    let documents = filter(media, { type: 'document' });
    let autodocs = filter(media, { type: 'autodoc' });

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Añadir</div>
      </div>
    );

    const statusBadge = () => {
      if(!task) return;
      const status = find(Status_List, { id: task.Status })
      return (
        <div>
          <Button style={{cursor: 'initial'}}>
            <Badge color={status ? status.color : 'purple'} text={status ? status.name : '...'} />
          </Button>
        </div>
      );
    }

    const deleteButton = () => isAdmin ? (
      <Button
        type="danger"
        icon="delete"
        ghost
        onClick={(e) => this.deleteTask(e)}
        rel="noopener noreferrer"
      >
        Eliminar tarea
      </Button>
    ) : null;

    return (
      <CleanLayout
        {...this.props}
        title={`Tarea ${task ? task.Installation_No : ''}`}
        extra={[statusBadge(), deleteButton()]}
      >
        <Row>
          <Col span={12}>
            <div style={{ background: '#fff', margin: 24, padding: 24, minHeight: 280 }}>
              <h2>Información de la tarea</h2>
              <Divider/>
              <ul className="customer-data">
                {TasksInfoFields.map(data => {
                  if(task) {
                    switch (data.id) {
                      case 'Phone_No':
                        let phones = [];
                        if(task['Phone_No']) phones.push(task['Phone_No']);
                        if(task['Phone_No_2']) phones.push(task['Phone_No_2']);
                        if(task['Phone_No_3']) phones.push(task['Phone_No_3']);
                        return (
                          <li key={data.id}>
                            <span className="title">{data.label}</span>
                            <span className="value">{phones.join(', ')}</span>
                          </li>
                        )
                      case 'Unit_Cost_LCY':
                        return (
                          <li key={data.id}>
                            <span className="title">{data.label}</span>
                            <span className="value">{task[data.id] ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(task[data.id]) : '0,00 €'}</span>
                          </li>
                        );
                      default:
                        return (
                          <li key={data.id}>
                            <span className="title">{data.label}</span>
                            <span className="value">{this.renderDataField(task[data.id])}</span>
                          </li>
                        )
                    }
                  }
                  return null;
                })}
              </ul>
            </div>
           {!!note && <div style={{ background: '#fff', margin: 24, padding: 24, minHeight: 50 }}>
              <h2>Nota</h2>
              <Divider/>
               <p>{note}</p>
            </div>
            }
            <div style={{ background: '#fff', margin: 24, padding: 24, minHeight: 50 }}>
              <h2>Imágenes adjuntas</h2>
              <Divider/>
                <Upload
                  beforeUpload={this.beforeUploadImages}
                  customRequest={this.handleImageUpload}
                  onChange={this.onImageChange}
                  onRemove={this.deleteFile}
                  listType="picture-card"
                  fileList={images.map(item => {
                    console.log("image map item ", item);
                    return {
                      uid: item.objectId,
                      name: item.fileName,
                      status: 'done',
                      url: item.url,
                    }
                  })}
                  onPreview={this.handlePreview}
                >
                  {uploadButton}
                </Upload>
            </div>
            {task && task.Service_Order_Type === 'CG EXT' ? filesForCGEXT.map(field => {
              return (
                <div style={{ background: '#fff', margin: 24, padding: 24, minHeight: 280 }}>
                  <h2>{field.name}</h2>
                  <Divider/>
                    <Upload
                      beforeUpload={this.beforeUpload}
                      customRequest={({file, onSuccess}) => this.handleFileUpload({file, fileName: field.filename, onSuccess})}
                      onChange={this.onImageChange}
                      onRemove={this.deleteFile}
                      listType="picture-card"
                      fileList={filter(documents.map(item => {
                        return {
                          uid: item.objectId,
                          name: item.fileName,
                          status: 'done',
                          url: item.url,
                        }
                      }), (item) => {
                        if(item.name && item.name.indexOf(field.filename) !== -1) {
                          return item;
                        }
                      })}
                      onPreview={this.handlePreview}
                    >
                      {uploadButton}
                    </Upload>
                </div>
              );
            }) : (
              <div style={{ background: '#fff', margin: 24, padding: 24, minHeight: 280 }}>
                <h2>Archivos adjuntos</h2>
                <Divider/>
                  <Upload
                    beforeUpload={this.beforeUpload}
                    customRequest={({file, onSuccess}) => this.handleFileUpload({file, onSuccess})}
                    onChange={this.onImageChange}
                    onRemove={this.deleteFile}
                    listType="picture-card"
                    fileList={documents.map(item => {
                      return {
                        uid: item.objectId,
                        name: item.fileName,
                        status: 'done',
                        url: item.url,
                      }
                    })}
                    onPreview={this.handlePreview}
                  >
                    {uploadButton}
                  </Upload>
              </div>
            )}
            {task && task.Service_Order_Type === 'CG EXT' ? (
              <div style={{ background: '#fff', margin: 24, padding: 24, minHeight: 280 }}>
                <h2>Otros adjuntos</h2>
                <Divider/>
                  <Upload
                    customRequest={({file, onSuccess}) => this.handleFileUpload({file, onSuccess})}
                    onChange={this.onImageChange}
                    onRemove={this.deleteFile}
                    listType="picture-card"
                    fileList={filter(documents.map(item => {
                      return {
                        uid: item.objectId,
                        name: item.fileName,
                        status: 'done',
                        url: item.url,
                      }
                    }), (item) => {
                      let match = false;
                      filesForCGEXT.forEach(field => {
                        if(item.name && item.name.indexOf(field.filename) !== -1) {
                          return item;
                        }
                      });
                      if(!match) {
                        return item;
                      }
                    })}
                    onPreview={this.handlePreview}
                  >
                    {uploadButton}
                  </Upload>
              </div>
            ) : null}
          </Col>
          <Col span={12}>
            <div style={{ background: '#fff', margin: 24, padding: 24, minHeight: 50 }}>
              <h2>{task && task.Completed ? 'Resultado del pedido' : 'Formulario del pedido'}</h2>
              <Divider/>
              {form && task ? <GlobalForm fields={form.schema} taskData={taskData} task={task} completed={task.Completed} onSubmited={() => {
                this.fetch()
              }} /> : (
                <h4 style={{textAlign: 'center', color: 'rgba(0, 0, 0, 0.25)', margin: '20px 0'}}>No se han recogido los datos de la tarea</h4>
              )}
            </div>
            <div style={{ background: '#fff', margin: 24, padding: 24, minHeight: 50 }}>
              <h2>Documentos autogenerados</h2>
              <Divider/>
                <Upload
                  listType="picture-card"
                  disabled={true}
                  fileList={autodocs.map(item => {
                    return {
                      uid: item.objectId,
                      name: item.fileName,
                      status: 'done',
                      url: item.url,
                    }
                  })}
                  onPreview={this.handlePreview}
                >
                </Upload>
            </div>
            <div style={{ background: '#fff', margin: 24, padding: 24, minHeight: 50 }}>
              <h2>Incidencia registradas</h2>
              <Divider/>
                <List
                  itemLayout="horizontal"
                  dataSource={alerts}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        title={<a href="https://ant.design">{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</a>}
                        description={item.text}
                      />
                    </List.Item>
                  )}
                  locale={{
                    emptyText: 'No hay registros de incidencias'
                  }}
                />
            </div>
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
              {previewFile && previewFile.base64.indexOf("sp.primagas.es") === -1  ? (
                <div>
                  {previewFile.name.indexOf('png') !== -1 || previewFile.name.indexOf('jpg') !== -1 || previewFile.name.indexOf('jpeg') !== -1 ? (
                    <a href={previewFile.url} target="_blank" rel="noreferrer">
                      <img alt="example" style={{ width: '100%' }} src={previewFile.base64} />
                    </a>
                  ) : null}
                  {previewFile.name.indexOf('pdf') !== -1 ? (
                    <iframe src={previewFile.base64} width={'100%'} height={'400px'} style={{border:0, marginTop:15}} title="filePreview" />
                  ) : null}
                  <br />
                  <small>{previewFile.name}</small>
                  <br />
                  <Button onClick={() => window.open(previewFile.url, '_blank')}>
                    <Icon type="link" /> Abrir enlace
                  </Button>
                </div>
              ) : (
                <div>No se puede previsualizar la información</div>
              )}
            </Modal>
          </Col>
        </Row>
      </CleanLayout>
    )
  }
}
